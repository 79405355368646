import { ReactElement } from "react";
import { AlertView } from "./alertView";
import { useAlertViewModel } from "./alertViewModel";

export type AlertProps = {
    id?: string;
    level?: AlertLevel;
    header?: string;
    text?: string;
    children?: any;
    more?: Function;
    ctaText?: string;
};

export type AlertLevel = "success" | "warning" | "error" | "info";

function defaults(props: AlertProps): AlertProps {
    return { level: "info", ...props };
}

export function Alert(props: AlertProps): ReactElement {
    props = defaults(props);
    const { state } = useAlertViewModel(props);

    return <AlertView {...props} {...state} />;
}
