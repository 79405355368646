import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { useSettingsContext } from "../../../../api/backend";
import { AuthenticationCookie } from "../../../../types";
import { Decrypt, Encrypt } from "../../../../utility";
import { Modal, ModalProps } from "../../../generic";
import TrustThisDeviceView from "./trustThisDeviceView";
import { useTrustThisDeviceViewModel } from "./trustThisDeviceViewModel";

type TrustThisDeviceProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function TrustThisDeviceModal(props: ModalProps & TrustThisDeviceProps): ReactElement {
    return (
        <Modal show={props.show}>
            <TrustThisDeviceModel {...props} />
        </Modal>
    );
}

export function TrustThisDeviceModel(props: TrustThisDeviceProps): ReactElement {
    const { hooks, state, api } = useTrustThisDeviceViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        return () => {
            hooks.removeValue(["showTrust"]);
        };
    }, []);

    async function onSubmit(values: { trust: boolean }, actions: FormikHelpers<any>) {
        if (values.trust) {
            onTrust().finally(() => {
                actions.setSubmitting(false);
            });
        } else {
            onNotNow().finally(() => {
                actions.setSubmitting(false);
            });
        }
    }

    async function onNotNow() {
        await continueSubmit().then(() => {
            props.setShow(false);
        });
    }

    async function onTrust() {
        recursiveRemove();

        const username = Decrypt(sessionStorage["username"]);
        const password = sessionStorage["password"];

        let cookieValue = {
            username: username,
            password: password,
            refresh_token: state.refresh_token
        } as AuthenticationCookie;

        let cookie = Encrypt(cookieValue);

        let exp = new Date();
        exp.setDate(exp.getDate() + 60);

        hooks.setCookies("AuthenticationCookie", cookie, {
            expires: exp,
            secure: true,
            sameSite: "none"
        });

        await continueSubmit().then(() => {
            props.setShow(false);
        });
    }

    function recursiveRemove(attempts: number = 3) {
        let cookie = hooks.getCookies["AuthenticationCookie"];

        if (cookie !== undefined && attempts > 0) {
            hooks.removeCookie("AuthenticationCookie", { path: "/" });
            hooks.removeCookie("AuthenticationCookie", { path: "/auth" });
            recursiveRemove(attempts - 1);
        }
    }

    const continueSubmit = async (): Promise<void> => {
        return api
            .userAccounts()
            .then(async (res) => {
                if (!isEMessengerDown) {
                    await api.getPreferences();
                }
            })
            .finally(() => {
                hooks.removeValue(["username", "password"]);
            });
    };

    return <TrustThisDeviceView onSubmit={onSubmit} />;
}
