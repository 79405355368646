import { AxiosResponse } from "axios";
import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";
import {
    ElectronicDeliveryPreferences,
    UserAccount,
    useAccountContext,
    useDeliveryPreferencesContext
} from "../../../../api/backend";
import { useIdentityConnectContext } from "../../../../api/identityServer";
import { useSessionStorage } from "../../../../hooks";

type TrustThisDeviceViewModel = {
    hooks: TrustThisDeviceHooks;
    state: TrustThisDeviceState;
    api: TrustThisDeviceApi;
};

type TrustThisDeviceHooks = {
    getCookies: { [x: string]: any };
    setCookies: (name: string, value: any, options?: CookieSetOptions | undefined) => void;
    removeCookie: (name: string, options?: CookieSetOptions | undefined) => void;
    removeValue: (names: string[]) => void;
};

export type TrustThisDeviceState = {
    refresh_token: string;
};

type TrustThisDeviceApi = {
    userAccounts: () => Promise<AxiosResponse<UserAccount[], any>>;
    getPreferences: () => Promise<AxiosResponse<ElectronicDeliveryPreferences[], any>>;
};

const storageKeys = ["showTrust", "username", "password"];
const cookieKeys = ["AuthenticationCookie"];

export function useTrustThisDeviceViewModel(): TrustThisDeviceViewModel {
    const [getCookies, setCookies, removeCookie] = useCookies(cookieKeys);
    const { refresh_token } = useIdentityConnectContext();
    const { UserAccounts } = useAccountContext();
    const { Get: getPreferences } = useDeliveryPreferencesContext();
    const [, , removeValue] = useSessionStorage(storageKeys);

    async function userAccounts(): Promise<AxiosResponse<UserAccount[], any>> {
        return await UserAccounts();
    }

    return {
        hooks: { getCookies, setCookies, removeCookie, removeValue },
        state: { refresh_token },
        api: {
            userAccounts,
            getPreferences
        }
    };
}
