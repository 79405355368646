import { AxiosError, AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { v4 } from "uuid";
import { OnlineActions, UserConsentResponse, useSettingsContext } from "../../../api/backend";
import { MFAOptions } from "../../../api/identityServer";
import { AuthenticationCookie } from "../../../types";
import { Decrypt, Encrypt, EqualsIgnoreCase, IsNullOrEmpty, SaltPassword } from "../../../utility";
import { LoginErrors, LoginFields, LoginView } from "./loginView";
import { useLoginViewModel } from "./loginViewModel";

export function LoginModel(): ReactElement {
    const { hooks, state, api } = useLoginViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        hooks.clearIdentity("all");
        hooks.clearMfa("all");
        hooks.removeValue([
            "accounts",
            "accountMenus",
            "appSettings",
            "password",
            "username",
            "showTrust",
            "deliveryPreferences",
            "controlsStatus",
            "deliveryLandingPrompted"
        ]);

        if (hooks.storage["OneTrustSync"] === "true") {
            hooks.removeValue(["OneTrustSync"]);
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        api.loginCms()
            .then((res) => {
                state.setModalCms(res.data);
            })
            .catch((err: AxiosError) => {
                console.log(err);
            });
    }, []);

    function onForgotPassword() {
        state.setSecurityType("ForgotPassword");
        hooks.navigate("/auth/forgot-password");
    }

    function onForgotUsername() {
        state.setSecurityType("ForgotUsername");
        hooks.navigate("/auth/forgot-username");
    }

    function onEnroll() {
        state.setSecurityType("Enrollment");
        let id = v4();
        hooks.navigate({
            pathname: "/auth/enroll",
            search: `?id=${id}`
        });
    }

    async function onSubmit(values: LoginFields, actions: FormikHelpers<LoginFields>) {
        clearContexts();
        if (hasErrors(values)) {
            actions.setSubmitting(false);
            return;
        }

        let cookie = Decrypt<AuthenticationCookie>(hooks.cookies["AuthenticationCookie"]);
        if (isCookieValid(values, cookie)) {
            await trustedLogin(values, actions, cookie);
        } else {
            await standardLogin(values, actions);
        }
    }

    function clearContexts() {
        hooks.clearIdentity("all");
        hooks.clearAccounts(true, true);
        hooks.clearMfa("all");
    }

    function hasErrors(values: LoginFields): boolean {
        const errors = ValidateInputFieldsBlank(values);
        const check = Object.keys(errors).length !== 0;
        if (check) {
            state.setLoginErrors(errors);
        }

        return check;
    }

    function isCookieValid(values: LoginFields, cookie: AuthenticationCookie): boolean {
        if (cookie.refresh_token !== undefined) {
            if (cookie.username === values.username && cookie.password === SaltPassword(values.password)) {
                return true;
            }
        }
        return false;
    }

    async function trustedLogin(
        values: LoginFields,
        actions: FormikHelpers<LoginFields>,
        cookie: AuthenticationCookie
    ) {
        let loginAllowed = false;
        api.refreshToken(cookie.refresh_token)
            .then(async (tokenRes) => {
                await api
                    .userAccounts(tokenRes.data.access_token!)
                    .then(async (accountRes) => {
                        if (accountRes.data.length > 0) {
                            hooks.initializeSwrve(accountRes.data[0].applicantHandle);

                            await Promise.all([
                                api.getDigitalActions(tokenRes.data.access_token!, accountRes.data),
                                api.userConsentGet(accountRes.data[0].onlineUserId, accountRes.data[0].applicantHandle),
                                !isEMessengerDown ? api.getPreferences(tokenRes.data.access_token!) : Promise.resolve(),
                                api.getSettings()
                            ]).then(
                                (
                                    res: [
                                        AxiosResponse<OnlineActions[], any>,
                                        AxiosResponse<UserConsentResponse, any>,
                                        unknown,
                                        unknown
                                    ]
                                ) => {
                                    hooks.updateConsumerGuid(res[1].data.consumerGuid);
                                    hooks.updateJwt(res[1].data.jwt);
                                    hooks.syncOneTrustConsentProfile(res[1].data.consumerGuid, res[1].data.jwt);
                                    loginAllowed = checkOnlineActions(actions, res[0].data);
                                }
                            );
                        }
                    })
                    .finally(() => {
                        if (loginAllowed) {
                            state.setSecurityType("Login");
                            actions.setSubmitting(false);
                            hooks.navigate(
                                IsNullOrEmpty(state.deepLink) || state.deepLink === "/"
                                    ? "/account/dashboard"
                                    : state.deepLink
                            );
                        }
                    });
            })
            .catch(async () => {
                standardLogin(values, actions);
            });
    }

    function checkOnlineActions(actions: FormikHelpers<LoginFields>, onlineActions: OnlineActions[] | undefined) {
        let login = false;
        let onlineActionLocal = onlineActions || state.digitalActions;
        if (onlineActionLocal !== undefined) {
            onlineActionLocal.forEach((onlineAction) => {
                if (!login)
                    login =
                        onlineAction.access.find((x) => EqualsIgnoreCase(x.onlineActionName, "login"))?.isAllowed ||
                        false;
            });
        }
        if (!login && (onlineActionLocal?.length ?? 0) > 0) {
            state.setShowErrorModal(true);
            actions.resetForm({ values: state.initialValues });
        }

        return login;
    }

    async function standardLogin(values: LoginFields, actions: FormikHelpers<LoginFields>) {
        await api
            .login(values.username, values.password)
            .then(async (mfaRes) => {
                const errors = ValidateFields(mfaRes.data.messagingInfo!);
                hooks.initErrorConstants(mfaRes.data.messagingInfo?.isCorpCard ?? false);
                state.setLoginErrors(errors);
                checkErrors(actions, errors);

                if (mfaRes.data.messagingInfo!.customDataClasSaveResult) {
                    actions.resetForm({ values: state.initialValues });
                    setFailedFocus(values);
                    return;
                } else if (!checkOnlineActions(actions, mfaRes.data.accountLevelMenu)) {
                    //Login Not allowed
                    return;
                } else if (Object.keys(errors).length === 0) {
                    if (
                        mfaRes.data.messagingInfo?.onlineUserId !== undefined &&
                        mfaRes.data.messagingInfo?.onlineUserId !== 0 &&
                        mfaRes.data.messagingInfo?.applicantHandle !== undefined &&
                        mfaRes.data.messagingInfo?.applicantHandle !== null
                    ) {
                        await api.getSettings();
                        await api
                            .userConsentGet(
                                mfaRes.data.messagingInfo?.onlineUserId,
                                mfaRes.data.messagingInfo?.applicantHandle
                            )
                            .then(async (consentRes) => {
                                hooks.updateConsumerGuid(consentRes.data.consumerGuid);
                                hooks.updateJwt(consentRes.data.jwt);
                                hooks.syncOneTrustConsentProfile(consentRes.data.consumerGuid, consentRes.data.jwt);
                            });

                        hooks.initializeSwrve(mfaRes.data.messagingInfo?.applicantHandle.toString());
                    }

                    hooks.storeValue("username", Encrypt(values.username));
                    hooks.storeValue("password", SaltPassword(values.password));

                    state.setMfaOptions(mfaRes.data.messagingInfo!);
                    state.setSecurityType("Login");
                    hooks.clearPreferences();
                    updateRememberMe(values, actions);

                    if (mfaRes.data.messagingInfo!.isPasswordReset) {
                        state.setSecurityType("TempPassword");
                    }

                    hooks.navigate("/auth/request-code");
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    function checkErrors(actions: FormikHelpers<LoginFields>, errors: LoginErrors) {
        if (errors.invalidLogin !== "" && errors.invalidLogin !== undefined) {
            actions.resetForm({ values: state.initialValues });
            state.setShowErrorModal(true);
        } else if (errors.password?.includes("You have failed 3 attempts")) {
            actions.resetForm({ values: state.initialValues });
            state.setSecurityType("ForgotPassword");
            state.setshowModalLockout(true);
        }
    }

    function setFailedFocus(values: LoginFields) {
        if (
            hooks.storageLocal["rememberMe"] !== "true" ||
            (hooks.storageLocal["rememberMe"] === "true" && hooks.storageLocal["PremierUserName"] !== values.username)
        ) {
            values.username = "";
            (document.querySelector(`input[id=username]`) as HTMLInputElement)?.focus();
        } else {
            (document.querySelector(`input[id=password]`) as HTMLInputElement)?.focus();
        }
    }

    function updateRememberMe(values: LoginFields, actions: FormikHelpers<LoginFields>) {
        if (values.checkbox) {
            actions.setValues({
                username: values.username,
                password: "",
                checkbox: values.checkbox
            });
            hooks.storeLocal("rememberMe", values.checkbox.toString());
            hooks.storeLocal("PremierUserName", values.username);
        } else {
            actions.setValues({ username: "", password: "", checkbox: false });
            hooks.removeLocal("rememberMe");
            hooks.removeLocal("PremierUserName");
        }
    }

    return (
        <LoginView
            {...state}
            onForgotPassword={onForgotPassword}
            onForgotUsername={onForgotUsername}
            onEnroll={onEnroll}
            onSubmit={onSubmit}
        />
    );
}

const ValidateFields = (values: MFAOptions): LoginErrors => {
    const errors: LoginErrors = {};
    let error: string | undefined = "";
    if (values?.customDataClasSaveResult?.errors) {
        if (values?.customDataClasSaveResult.errors[0].errorField === "InvalidLogin") {
            errors.invalidLogin = values?.customDataClasSaveResult.errors[0].errorMessage;
        } else if (values?.customDataClasSaveResult.errors[0].errorMessage) {
            error = values?.customDataClasSaveResult.errors[0].errorMessage;
        }
    }

    switch (error) {
        case "We're sorry, but the username and/or password you entered are incorrect. Please sign in again.":
            errors.password =
                "We're sorry, but the username and/or password you entered are incorrect. Please try again.";
            break;
        case "For your security, your account has been suspended for 10 minutes due to multiple failed login attempts":
            errors.password =
                "You have failed 3 attempts. You can try again in 10 minutes or reset your password by selecting 'Forgot password' below.";
            break;
    }

    return errors;
};

const ValidateInputFieldsBlank = (values: LoginFields): LoginErrors => {
    const errors: LoginErrors = {};

    if (IsNullOrEmpty(values.username)) {
        errors.username = "The Username field is required";
    }

    if (IsNullOrEmpty(values.password)) {
        errors.password = "The Password field is required";
    }

    return errors;
};
