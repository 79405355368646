import { AxiosResponse } from "axios";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    AppSettings,
    ElectronicDeliveryPreferences,
    LoginCms,
    OnlineActions,
    UserAccount,
    UserConsentResponse,
    useAccountContext,
    useCms,
    useDeliveryPreferencesContext,
    useDigitalActionsContext,
    useSettingsContext,
    useUserConsentContext
} from "../../../api/backend";
import {
    ClearIdentityOptions,
    ConnectTokenResponse,
    MFAOptions,
    MFAReturn,
    useIdentityConnectContext,
    useIdentityPremierContext
} from "../../../api/identityServer";
import { useDeepLinkContext, useMfaContext, useOneTrustContext, useSwrveContext } from "../../../contexts";
import { ClearMfaOptions, SecurityType } from "../../../contexts/mfaContext";
import { useLocalStorage, useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { Decrypt, HelpLine } from "../../../utility";
import { GenericModalProps } from "../../generic";
import { LoginErrors, LoginFields } from "./loginView";

type LoginViewModel = {
    hooks: LoginHooks;
    state: LoginState;
    api: LoginApi;
};

type LoginHooks = {
    navigate: NavigateFunction;
    clearMfa: (option: ClearMfaOptions) => void;
    clearIdentity: (option: ClearIdentityOptions) => void;
    clearAccounts: (accounts?: boolean | undefined, options?: boolean | undefined) => void;
    storageLocal: { [x: string]: any };
    storeLocal: (name: string, value: any) => void;
    removeLocal: (name: string) => void;
    storage: { [x: string]: any };
    storeValue: (name: string, value: any) => void;
    removeValue: (names: string[]) => void;
    cookies: { [x: string]: any };
    initializeSwrve: (applicantHandle: string) => void;
    syncOneTrustConsentProfile: (consumerGuid: string, jwt: string) => void;
    updateConsumerGuid: (consumerGuid?: string | undefined) => void;
    updateJwt: (jwt?: string | undefined) => void;
    clearPreferences: () => void;
    initErrorConstants: (isCorpCard: boolean) => void;
};

export type LoginState = {
    size: Size;
    deepLink: string;
    modalCms: LoginCms;
    setModalCms: React.Dispatch<React.SetStateAction<LoginCms>>;
    showModalLockout: boolean;
    setshowModalLockout: React.Dispatch<React.SetStateAction<boolean>>;
    showModalStayOnPage: boolean;
    setshowModalStayOnPage: React.Dispatch<React.SetStateAction<boolean>>;
    showAssistance: boolean;
    setShowAssistance: React.Dispatch<React.SetStateAction<boolean>>;
    loginErrors: LoginErrors;
    setLoginErrors: React.Dispatch<React.SetStateAction<LoginErrors>>;
    showErrorModal: boolean;
    setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
    initialValues: LoginFields;
    setSecurityType: (value: SecurityType) => void;
    setMfaOptions: (value: MFAOptions) => void;
    errorConstants: GenericModalProps;
    digitalActions: OnlineActions[] | undefined;
};

type LoginApi = {
    loginCms: () => Promise<AxiosResponse<LoginCms, any>>;
    login: (username: string, password: string) => Promise<AxiosResponse<MFAReturn, any>>;
    refreshToken: (refresh_token: string) => Promise<AxiosResponse<ConnectTokenResponse, any>>;
    userAccounts: (token: string) => Promise<AxiosResponse<UserAccount[], any>>;
    getDigitalActions: (token: string, accounts: UserAccount[]) => Promise<AxiosResponse<OnlineActions[], any>>;
    userConsentGet: (onlineUserId: number, applicantHandle: string) => Promise<AxiosResponse<UserConsentResponse, any>>;
    getPreferences: (token: string) => Promise<AxiosResponse<ElectronicDeliveryPreferences[], any>>;
    getSettings: () => Promise<AxiosResponse<AppSettings, any>>;
};

const localKeys = ["rememberMe", "PremierUserName"];
const sessionKeys = [
    "username",
    "password",
    "accounts",
    "accountMenus",
    "appSettings",
    "showTrust",
    "deliveryPreferences"
];
const cookieKeys = ["AuthenticationCookie"];

export function useLoginViewModel(): LoginViewModel {
    const { size } = useViewport(false, true);
    const navigate = useNavigate();
    const { securityType, setSecurityType, setMfaOptions, clear: clearMfa } = useMfaContext();
    const { clear: clearIdentity, RefreshToken } = useIdentityConnectContext();
    const { UserAccounts, clear: clearAccounts } = useAccountContext();
    const { Get: getActions, digitalActions } = useDigitalActionsContext();
    const { Get: getConsent } = useUserConsentContext();
    const [storageLocal, storeLocal, removeLocal] = useLocalStorage(localKeys);
    const [storage, storeValue, removeValue] = useSessionStorage(sessionKeys);
    const [cookies] = useCookies(cookieKeys);
    const { initializeSwrve } = useSwrveContext();
    const { syncOneTrustConsentProfile, updateConsumerGuid, updateJwt } = useOneTrustContext();
    const { deepLink } = useDeepLinkContext();
    const [modalCms, setModalCms] = useState({} as LoginCms);
    const [showModalLockout, setshowModalLockout] = useState(false);
    const [showModalStayOnPage, setshowModalStayOnPage] = useState(false);
    const [showAssistance, setShowAssistance] = useState(false);
    const [loginErrors, setLoginErrors] = useState({} as LoginErrors);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const initialValues = RememberMeLoad(storageLocal, storage, securityType);
    const { Get: getPreferences, clear: clearPreferences } = useDeliveryPreferencesContext();
    const { Login } = useIdentityPremierContext();
    const { Login: loginCms } = useCms();
    const [errorConstants, setErrorConstants] = useState<GenericModalProps>(errorConstantsProps(false));
    const { Get: getSettings } = useSettingsContext();

    function errorConstantsProps(isCorpCard: boolean): GenericModalProps {
        return {
            headerText: "Login not Available",
            bodyText: `Login is not available, or the account has expired. If you have questions, contact our representatives at ${HelpLine(isCorpCard)}.`,
            primaryButton: {
                text: "Close",
                action: () => setShowErrorModal(false)
            }
        };
    }

    function initErrorConstants(isCorpCard: boolean) {
        setErrorConstants(errorConstantsProps(isCorpCard));
    }

    async function login(username: string, password: string): Promise<AxiosResponse<MFAReturn, any>> {
        return await Login(username, password);
    }

    async function refreshToken(refresh_token: string): Promise<AxiosResponse<ConnectTokenResponse, any>> {
        return await RefreshToken(refresh_token, true);
    }

    async function userAccounts(token: string): Promise<AxiosResponse<UserAccount[], any>> {
        return await UserAccounts(token);
    }

    async function getDigitalActions(
        token: string,
        accounts: UserAccount[]
    ): Promise<AxiosResponse<OnlineActions[], any>> {
        return await getActions(accounts[0].accountHandle, token);
    }

    async function userConsentGet(
        onlineUserId: number,
        applicantHandle: string
    ): Promise<AxiosResponse<UserConsentResponse, any>> {
        return await getConsent(onlineUserId, applicantHandle);
    }

    return {
        hooks: {
            navigate,
            clearMfa,
            clearIdentity,
            clearAccounts,
            storageLocal,
            storeLocal,
            removeLocal,
            storage,
            storeValue,
            removeValue,
            cookies,
            initializeSwrve,
            syncOneTrustConsentProfile,
            updateConsumerGuid,
            updateJwt,
            clearPreferences,
            initErrorConstants
        },
        state: {
            size,
            deepLink,
            modalCms,
            setModalCms,
            showModalLockout,
            setshowModalLockout,
            showModalStayOnPage,
            setshowModalStayOnPage,
            showAssistance,
            setShowAssistance,
            loginErrors,
            setLoginErrors,
            showErrorModal,
            setShowErrorModal,
            initialValues,
            setSecurityType,
            setMfaOptions,
            errorConstants,
            digitalActions
        },
        api: {
            loginCms,
            login,
            refreshToken,
            userAccounts,
            getDigitalActions,
            userConsentGet,
            getPreferences,
            getSettings
        }
    };
}

function RememberMeLoad(
    storageLocal: { rememberMe?: any; PremierUserName?: any },
    storage: { username?: any; password?: any },
    securityType: SecurityType | undefined
): LoginFields {
    const rememberMeBool = storageLocal["rememberMe"] === "true";
    let initialValues: LoginFields = {
        username: "",
        password: "",
        checkbox: false
    };
    if (rememberMeBool) {
        const username = storageLocal["PremierUserName"] ?? "";
        initialValues = {
            username: username,
            password: "",
            checkbox: rememberMeBool
        };
    }

    if (storage["username"] !== undefined && securityType === "ForgotUsername") {
        initialValues.username = Decrypt(storage["username"]);
    }

    return initialValues;
}
