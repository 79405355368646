import { ReactElement } from "react";
import { ButtonPro } from "../..";
import { ArrowRight, Check, Error, Info, Warning } from "../../icons";
import { AlertLevel, AlertProps } from "./alert";
import "./alert.scss";
import { AlertState } from "./alertViewModel";

type AlertViewProps = AlertProps & AlertState;

export function AlertView(props: AlertViewProps): ReactElement {
    const ctaText = props.ctaText !== undefined ? props.ctaText : "More";

    return (
        <div id={props.id} className={props.className} role="alert">
            <div className="alert-tab" />
            <div className="alert-contents">
                {getIcon(props.level!)}
                <div className={`alert-wrapper${props.size}`}>
                    <div className={`alert-text${props.more !== undefined ? "-more" : ""}${props.size}`}>
                        <p className="alert-header">{props.header}</p>
                        {props.children !== undefined && typeof props.children === "object" ? (
                            props.children
                        ) : (
                            <h6 className="alert-body">{props.children ? props.children : props.text}</h6>
                        )}
                    </div>
                    {props.more !== undefined ? (
                        <ButtonPro className="alert-expand" variant="neutral" onClick={() => props.more!()}>
                            <p className="alert-more">{ctaText}</p>
                            <ArrowRight className="alert-arrow" />
                        </ButtonPro>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
}

const getIcon = (level: AlertLevel): ReactElement => {
    switch (level) {
        case "success":
            return <Check className="alert-icon" />;
        case "warning":
            return <Warning className="alert-icon" />;
        case "error":
            return <Error className="alert-icon" />;
        case "info":
            return <Info className="alert-icon" />;
    }
};
