import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { TransactionDetails, useTransactionsContext } from "../../../api/backend";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { ActivityProps } from "./activityModel";

type ActivityViewModel = {
    context: ActivityContext;
    hooks: ActivityHooks;
    state: ActivityState;
    api: ActivityApi;
};

type ActivityContext = {
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
};

type ActivityHooks = {
    navigate: NavigateFunction;
};

export type ActivityState = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    history: TransactionDetails[];
    setHistory: React.Dispatch<React.SetStateAction<TransactionDetails[]>>;
    years: number[];
    setYears: React.Dispatch<React.SetStateAction<number[]>>;
    filteredHistory: TransactionDetails[];
    setFilteredHistory: React.Dispatch<React.SetStateAction<TransactionDetails[]>>;
    displayedHistory: TransactionDetails[];
    setDisplayedHistory: React.Dispatch<React.SetStateAction<TransactionDetails[]>>;
    filter: number;
    setFilter: React.Dispatch<React.SetStateAction<number>>;
    rows: ReactElement[];
    setRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    seeMore: boolean;
    setSeeMore: React.Dispatch<React.SetStateAction<boolean>>;
    size: Size;
};

type ActivityApi = {
    transactionsRecent: () => Promise<AxiosResponse<TransactionDetails[], any>>;
    transactionsAll: () => Promise<AxiosResponse<TransactionDetails[], any>>;
};

export function useActivityViewModel(props: ActivityProps): ActivityViewModel {
    const { size } = useViewport(false, true);
    const navigate = useNavigate();
    const { Recent, All } = useTransactionsContext();
    const [curIndex, setCurIndex] = useState(undefined as undefined | number);
    const [history, setHistory] = useState([] as TransactionDetails[]);
    const [years, setYears] = useState([] as number[]);
    const [filteredHistory, setFilteredHistory] = useState([] as TransactionDetails[]);
    const [displayedHistory, setDisplayedHistory] = useState([] as TransactionDetails[]);
    const [filter, setFilter] = useState(0 as number);
    const [rows, setRows] = useState([] as ReactElement[]);
    const [seeMore, setSeeMore] = useState(false);
    const [loading, setLoading] = useState(false);

    async function transactionsRecent(): Promise<AxiosResponse<TransactionDetails[], any>> {
        return Recent(props.account.accountHandle);
    }

    async function transactionsAll(): Promise<AxiosResponse<TransactionDetails[], any>> {
        return All(props.account.accountHandle);
    }

    return {
        context: {
            curIndex,
            setCurIndex
        },
        hooks: { navigate },
        state: {
            loading,
            setLoading,
            history,
            setHistory,
            years,
            setYears,
            filteredHistory,
            setFilteredHistory,
            displayedHistory,
            setDisplayedHistory,
            filter,
            setFilter,
            rows,
            setRows,
            seeMore,
            setSeeMore,
            size
        },
        api: {
            transactionsRecent,
            transactionsAll
        }
    };
}
