import { AxiosResponse } from "axios";
import { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { NavigateFunction, useLoaderData, useNavigate } from "react-router-dom";
import { CookieSetOptions } from "universal-cookie";
import { usePasswordContext } from "../../../api/backend";
import { CardNavContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { ChangePasswordData } from "./changePasswordLoader";

type ChangePasswordViewModel = {
    hooks: ChangePasswordHooks;
    state: ChangePasswordState;
    api: ChangePasswordApi;
};

type ChangePasswordHooks = {
    navigate: NavigateFunction;
    removeCookie: (name: string, options?: CookieSetOptions | undefined) => void;
};

export type ChangePasswordState = {
    size: Size;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
    passwordTouched: boolean;
    setPasswordTouched: React.Dispatch<React.SetStateAction<boolean>>;
    confirmTouched: boolean;
    setConfirmTouched: React.Dispatch<React.SetStateAction<boolean>>;
    showAlert: boolean;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
    changePasswordConfirmationText: string;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
};

type ChangePasswordApi = {
    passwordChange: (verifyPassword: string, newPassword: string) => Promise<AxiosResponse<any, any>>;
};

const cookieKeys = ["AuthenticationCookie"];

export function useChangePasswordViewModel(): ChangePasswordViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true, true);
    const { Change } = usePasswordContext();
    const [canContinue, setCanContinue] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [confirmTouched, setConfirmTouched] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const data = useLoaderData() as ChangePasswordData;
    const [, , removeCookie] = useCookies(cookieKeys);
    const { setShowNav } = useContext(CardNavContext);

    async function passwordChange(verifyPassword: string, newPassword: string): Promise<AxiosResponse<any, any>> {
        return Change({
            CurrentPassword: verifyPassword,
            DesiredPassword: newPassword,
            Recovery: false
        });
    }

    return {
        hooks: { navigate, removeCookie },
        state: {
            size,
            canContinue,
            setCanContinue,
            passwordTouched,
            setPasswordTouched,
            confirmTouched,
            setConfirmTouched,
            showAlert,
            setShowAlert,
            setShowNav,
            ...data
        },
        api: { passwordChange }
    };
}
